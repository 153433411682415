import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AppRoutes from "./AppRoutes";
import Navbar from "./page/Navbar";
import Footer from "./page/Footer";
import Token from "./page/Token";
import Reward from "./page/Reward";
import AOS from "aos"; // Import AOS library if not done already
import "aos/dist/aos.css";
import { useEffect } from "react";
import Team from "./page/Team";
import Solution from "./page/Solution";

function App() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <div className="">
      <div className="">
        <Navbar />
        <Routes>
          <Route path="/" element={<AppRoutes />} />
          <Route path="/token" element={<Token />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/team" element={<Team />} />
          <Route path="/service" element={<Solution />} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default App;
